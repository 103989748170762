<script>
export default {
  name: 'CompletitionCriteriaList',
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    steps: {
      type: Number
    },
    actualStep: {
      type: Number
    },
    criterias: {
      type: Array
    },
    fixed: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<template>
  <div class="completition-criteria-list--container" :class="{'fixed': fixed}">
    <div class="completition-criteria-list--head">
      <div class="completition-criteria-list--head-left">
        <h4>{{ $t(title) }}</h4>
        <p>{{ $t(description) }}</p>
      </div>
      <div class="completition-criteria-list--head-right">
        <v-progress-circular :rotate="-90" size="48" :width="7" :value="criterias.filter(c => c.completed).length * 100 / criterias.length" color="#36D75E">{{ criterias.filter(c => c.completed).length }}/ {{ criterias.length }}</v-progress-circular>
      </div>
    </div>
    <div class="completition-criteria-list--body">
      <div class="completition-criteria-list--item" v-for="item in criterias" :key="item.key">
        <div class="icon-wrapper" :class="{'completed': item.completed}"><v-icon color="#36D75E" size="12" v-if="item.completed">mdi-check</v-icon></div>
        <p>{{ $t(`completition.criterias:${item.key}`) }}</p>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.completition-criteria-list--container {
  margin-top: 40px;
  &.fixed {
    position: sticky;
    top: 100px;
  }
  .completition-criteria-list--head {
    display: flex;
    .completition-criteria-list--head-left {
      h4 {
        font-family: $lato;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #454545;
        text-align: left;
        margin-bottom: 8px;
      }
      p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #7E7D77;
        text-align: left;
      }
    }
    .completition-criteria-list--head-right {
      .v-progress-circular__underlay {
        background: rgba(54, 215, 94, 0.1) !important;
        stroke: rgba(54, 215, 94, 0.1) !important;
      }
      .v-progress-circular__info {
        color: #454545 !important;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  .completition-criteria-list--body {
      border: 1px solid rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      .completition-criteria-list--item {
        display: flex;
        padding: 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        background: #fff;
        .icon-wrapper {
          height: 16px;
          width: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid rgba(0, 0, 0, 0.06);
          border-radius: 100px;
          margin-right: 8px;
          &.completed {
            border: none;
            background: rgba(54, 215, 94, 0.1);
          }
        }
        p {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7E7D77;
          text-align: left;
          margin-bottom: 0;
        }
      }
    }
}
</style>
