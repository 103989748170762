<script>
import ProgressBar from '../../general/ProgressBar'
import ProfileInfoForm from './ProfileInfoForm.vue'
import { required, email, requiredIf, maxValue } from 'vuelidate/lib/validators'

export default {
  components: {
    ProgressBar,
    ProfileInfoForm
  },
  name: 'ImportResumeModal',
  data () {
    return {
      actualStep: 0,
      isResumeLoading: false,
      isAnalyzing: false,
      snackbar: false,
      resumeCounter: 0,
      selectedTip: '',
      tempArea: null,
      resume: null,
      tipsOnCompany: [
        'import.resume.modal:import.analysis.tipsOnCompany1',
        'import.resume.modal:import.analysis.tipsOnCompany2',
        'import.resume.modal:import.analysis.tipsOnCompany3'
      ],
      tips: [
        'import.resume.modal:import.analysis.tips1',
        'import.resume.modal:import.analysis.tips2',
        'import.resume.modal:import.analysis.tips3',
        'import.resume.modal:import.analysis.tips4',
        'import.resume.modal:import.analysis.tips5',
        'import.resume.modal:import.analysis.tips6',
        'import.resume.modal:import.analysis.tips7',
        'import.resume.modal:import.analysis.tips8',
        'import.resume.modal:import.analysis.tips9',
        'import.resume.modal:import.analysis.tips10'
      ],
      profileInfo: null,
      statusError: null,
      userInfo: {},
      socialMediaOptions: {
        Linkedin: 'https://www.linkedin.com/',
        Instagram: 'https://www.instagram.com/',
        Twitter: 'https://www.twitter.com/',
        Facebook: 'https://www.facebook.com/',
        GitHub: 'https://www.github.com/',
        Tiktok: 'https://www.tiktok.com/',
        Dribbble: 'https://www.dribbble.com/',
        Behance: 'https://www.behance.com/',
        Blog: 'https://',
        Site: 'https://'
      },
      formData: {
        emailResponse: null,
        countryCode: '',
        cityOptions: [],
        cityIdOptions: {},
        name: '',
        lastName: '',
        city: '',
        birthdate: '',
        region: '',
        skills: [],
        countryAbbr: '',
        email: '',
        phone: '',
        isPCD: false,
        pcdType: '',
        pcdCID: '',
        needAdaptation: false,
        pcdAdaptation: '',
        socialMedia: [],
        professionalTitle: '',
        professionalDescription: '',
        preferredAreas: [],
        hasProfessionalExp: true,
        professionalExperiences: [],
        hasAcademicExp: true,
        academicExperiences: [],
        languages: []
      }
    }
  },
  validations () {
    const validateBirthDate = (value) => {
      if (value === '') return true
      if (value.split('/').length !== 3) return false
      const [day, month, year] = value.split('/')

      if (!(day.length === 2) || !(month.length === 2) || !(year.length === 4)) return false

      const birthDate = new Date()
      birthDate.setMonth(month - 1)
      birthDate.setDate(day)
      birthDate.setFullYear(year)

      if (birthDate.getTime() > new Date().getTime()) return false
      const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
      if (!value.match(regex)) return false
      return true
    }
    const initEndDateYear = (value) => {
      if (value.actualWork || value.isStudying) return true
      if (!value.dateInit || !value.dateEnd) return true
      const yearInit = parseInt(value.dateInit)
      const yearEnd = parseInt(value.dateEnd)
      if (yearInit > yearEnd) return false
      return true
    }
    const initEndDate = (value) => {
      if (value.actualWork || value.isStudying) return true
      if (!value.dateInit || !value.dateEnd) return true
      const monthInit = parseInt(value.dateInit.split('/')[0])
      const monthEnd = parseInt(value.dateEnd.split('/')[0])
      const yearInit = parseInt(value.dateInit.split('/')[1])
      const yearEnd = parseInt(value.dateEnd.split('/')[1])
      if (yearInit > yearEnd) return false
      if (yearInit === yearEnd && monthInit > monthEnd) return false
      return true
    }
    const notUsedEmail = (value) => {
      if (!this.formData.emailResponse) return true
      if (this.formData.emailResponse.status === 200 && value !== this.getUser.email) return false
      return true
    }
    const isValidEmail = () => {
      if (!this.formData.emailResponse) return true
      if (this.formData.emailResponse.status === 400 || this.formData.emailResponse.status === 403) return false
      return true
    }
    const validFormatMonthYear = (value) => {
      if (!value) return true
      if (!value.includes('/')) return false
      const month = value.split('/')[0]
      const year = value.split('/')[1]
      if (month.length !== 2 || year.length !== 4) return false
      if (parseInt(month) > 12) return false
      return true
    }
    const limitDateMonthYear = (value) => {
      if (!value) return true
      if (!value.includes('/')) return false
      const actualYear = new Date().getUTCFullYear()
      const actualMonth = ('0' + (new Date().getUTCMonth() + 1)).slice(-2)
      const month = parseInt(value.split('/')[0])
      const year = parseInt(value.split('/')[1])
      if (year > actualYear) return false
      if (year === actualYear && month > actualMonth) return false
      return true
    }
    const validatePhoneDDD = (input) => {
      if (this.formData.countryCode !== 'br') return true
      if (!input) return false
      const value = input.replace('(', '').replace(')', '').replace(' ', '')
      if (value.length === 11) {
        var validDDD = [11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 63, 64, 65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99]
        const ddd = value.slice(0, 2)
        if (validDDD.indexOf(parseInt(ddd)) !== -1) return true
        return false
      } else return false
    }
    const validatePhoneNumber = (input) => {
      if (this.formData.countryCode !== 'br') return true
      if (!input) return false
      const value = input.replace('(', '').replace(')', '').replace(' ', '')
      if (value.length === 11) {
        var validFirstDigit = ['6', '7', '8', '9']
        const tel = value.slice(2)
        if (validFirstDigit.indexOf(tel[0]) !== -1) {
          return true
        }
        return false
      } else return false
    }
    const validateCountryAbbr = () => {
      if (this.formData.region !== 'BRA' || this.formData.region !== 'USA') {
        return true
      } else {
        return false
      }
    }
    const hasAcademicExperiencesValidation = (value) => {
      if (this.formData.academicExperiences.length > 0) {
        return true
      }
      return value === true
    }
    const hasProfessionalExperiencesValidation = (value) => {
      if (this.formData.professionalExperiences.length > 0) {
        return true
      }
      return value === true
    }
    const validateSocialMedias = (value) => {
      if (!value.type || !value.value) return false
      if (value.type === '' || value.value === '') return false
      const secureLink = this.socialMediaOptions[value.type]
      const startingLink = value.value.slice(0, secureLink.length)
      if (secureLink === startingLink) return true
      return false
    }
    return {
      formData: {
        name: { required },
        lastName: { required },
        birthdate: { validateBirthDate },
        countryAbbr: {
          validateCountryAbbr
        },
        // region: { required },
        city: { required },
        email: { required, email, notUsedEmail, isValidEmail },
        phone: { required, validatePhoneDDD, validatePhoneNumber },
        isPCD: '',
        pcdType: {
          required: requiredIf(function (formData) {
            return formData.isPCD
          })
        },
        pcdCID: {
          required: requiredIf(function (formData) {
            return formData.isPCD
          })
        },
        needAdaptation: '',
        pcdAdaptation: {
          required: requiredIf(function (formData) {
            return formData.needAdaptation
          })
        },
        socialMedia: {
          $each: { required, validateSocialMedias }
        },
        professionalTitle: { required },
        professionalDescription: { required },
        preferredAreas: { required },
        hasProfessionalExp: {
          hasProfessionalExperiencesValidation
        },
        professionalExperiences: {
          $each: {
            initEndDate,
            company: { required },
            title: { required },
            dateInit: { required, validFormatMonthYear, limitDateMonthYear },
            actualWork: '',
            dateEnd: {
              required: requiredIf(function (experience) {
                return !experience.actualWork
              }),
              validFormatMonthYear,
              limitDateMonthYear
            }
          }
        },
        hasAcademicExp: {
          hasAcademicExperiencesValidation
        },
        academicExperiences: {
          $each: {
            initEndDateYear,
            institution: { required },
            title: { required },
            dateInit: {
              required,
              limitDateYear: maxValue(new Date().getUTCFullYear())
            },
            isStudying: '',
            dateEnd: {
              required: requiredIf(function (experience) {
                return !experience.isStudying
              }),
              limitDateYear: maxValue(new Date().getUTCFullYear())
            }
          }
        },
        languages: {
          $each: {
            name: { required },
            proficiency: { required }
          }
        }
      }
    }
  },
  computed: {
    buttonText () {
      if (this.actualStep === 2) return 'global:confirm.resume'
      else if (this.actualStep > 2) return 'global:select.another.file'
      else return 'global:select.file'
    },
    getUser () {
      return this.$store.getters.getUser
    },
    status () {
      switch (this.actualStep) {
        case 0:
          return 'file.selection'
        case 1:
          return 'analysis'
        case 2:
          return 'revision'
        default:
          return 'error'
      }
    }
  },
  methods: {
    handleCheckError (message) {
      this.isAnalyzing = false
      this.snackbar = false
      this.actualStep = 4
      switch ((message.includes('400') || message.includes('404')) ? '404' : message.includes('413') ? '413' : message.includes('415') ? '415' : message.includes('error') ? 'error' : message) {
        case '413':
          this.statusError = { title: 'import.resume.modal:import.error.title', subtitle: 'import.resume.modal:error.banner.message.large' }
          break
        case '415':
          this.statusError = { title: 'import.resume.modal:import.error.title', subtitle: 'import.resume.modal:error.banner.message.format' }
          break
        case 'error':
          this.statusError = { title: 'import.resume.modal:import.error.title', subtitle: 'import.resume.modal:error.banner.message.format' }
          break
        case 'timeout':
          this.statusError = { title: 'import.resume.modal:import.timeout.title', subtitle: 'import.resume.modal:timeout.banner.message.format' }
          break
        case '404':
          this.statusError = { title: 'import.resume.modal:import.error.title', subtitle: 'import.resume.modal:error.banner.message' }
          break
        default:
          this.statusError = { title: 'import.resume.modal:import.error.title', subtitle: 'import.resume.modal:error.banner.message' }
      }
    },
    snackbarManagement () {
      if (this.resumeCounter % 2 === 0) {
        this.snackbar = false
        const userOnCompany = this.$store.getters.getUserWorkspaces.some(el => el.type === 'business')
        setTimeout(() => {
          const tipOrder = Math.floor(this.resumeCounter / 2)
          if (userOnCompany) {
            const index = tipOrder < this.tipsOnCompany.length ? tipOrder : tipOrder % this.tipsOnCompany.length
            this.selectedTip = this.tipsOnCompany[index]
          } else {
            const index = tipOrder < this.tips.length ? tipOrder : tipOrder % this.tips.length
            this.selectedTip = this.tips[index]
          }
          this.snackbar = true
        }, 500)
      }
      this.resumeCounter++
    },
    selectResume () {
      if (this.actualStep === 2) {
        this.$v.formData.$touch()
        if (!this.$v.formData.$error) {
          this.$emit('overwrite-form-data', this.formData)
          this.$emit('close')
        } else {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('global:validation.form.required')
          })
        }
      } else {
        document.getElementById('resumeUpload').click()
      }
    },
    storeResume (e) {
      this.resume = e.target.files[0]
      if (this.resume.type === 'application/pdf') {
        this.actualStep = 1
        this.submitResume()
      } else {
        this.actualStep = 4
      }
    },
    async submitResume () {
      const formData = new FormData()
      formData.append('enviroment', 'web')
      formData.append('resume', this.resume)
      this.isResumeLoading = true
      this.$store.dispatch('attemptGetUserProfile').then(data1 => {
        this.userInfo = {
          name: data1.personalDetails.firstName,
          lastName: data1.personalDetails.lastName,
          email: data1.personalDetails.email
        }
      })
      try {
        const response = await this.$store.dispatch('attemptImportUserResume', formData)
        this.isResumeLoading = false
        this.isAnalyzing = true
        if (response.status === 201) {
          if (!response || response.id) return null

          const wait = timeToDelay => new Promise(resolve => setTimeout(resolve, timeToDelay))
          const checkResume = async (parseId, started) => {
            try {
              this.snackbarManagement()
              const data = await this.$store.dispatch('attemptGetResumeResult', parseId)
              if (data.status === 'error') {
                this.handleCheckError(data.status)
              }
              if (data && data.status === 'complete') {
                this.isAnalyzing = false
                this.snackbar = false
                this.actualStep += 1
                const response = data.data
                response.personalDetails = {}
                this.formData = { ...this.formData, ...this.convertFormData(response), ...this.userInfo }
              }
              if (data && data.status === 'pending') {
                const total = new Date().getTime() - started.getTime()
                if (total > 181000) {
                  this.handleCheckError('timeout')
                  return
                }
                await wait(5000)
                return checkResume(parseId, started)
              }
              return null
            } catch (err) {
              this.handleCheckError(err.message)
            }
          }
          return checkResume(response.data.parse_id, new Date())
        }
      } catch (err) {
        this.handleCheckError(err.message)
      }
    }
  }
}
</script>
<template>
  <v-dialog :value="true" width="780" max-width="100%" z-index="105" persistent>
    <v-card class="import-resume--container">
      <div class="import-resume--head">
        <h4>{{ $t('import.resume.modal:title') }}</h4>
        <v-icon class="cursor-pointer" @click="$emit('close')">mdi-close</v-icon>
      </div>
      <div class="import-resume--body">
        <div class="progress-bar--wrapper">
          <progress-bar :value="actualStep * 50" :status="status" :indeterminate="isAnalyzing" :rightText="$t('global:steps', { total: 3, actual: actualStep > 2? 2 : actualStep + 1 })"></progress-bar>
        </div>
        <template v-if="actualStep === 0">
          <div class="import-resume--inner-body">
            <v-icon size="36" color="#2C2C2C">mdi-file-upload-outline</v-icon>
            <h4>{{ $t('import.resume.modal:import.title') }}</h4>
            <p><span class="icon-wrapper mr-1"><v-icon size="11">mdi-check</v-icon></span>{{ $t('import.resume.modal:import.instruction.1') }}</p>
            <p><span class="icon-wrapper mr-1"><v-icon size="11">mdi-check</v-icon></span>{{ $t('import.resume.modal:import.instruction.2') }}</p>
            <p>{{ $t('import.resume.modal:import.instruction.3') }}</p>
          </div>
        </template>
        <template v-if="actualStep === 1">
          <div class="import-resume--inner-body">
            <template v-if="!isAnalyzing">
              <v-icon size="36" color="#2C2C2C">mdi-progress-download</v-icon>
              <h4>{{ $t('import.resume.modal:import.loading.title') }}</h4>
              <p>{{ $t('import.resume.modal:import.loading.instruction') }}</p>
            </template>
            <template v-else>
              <p>{{ $t('import.resume.modal:import.analysis.instruction') }}</p>
            </template>
             <v-snackbar
                v-model="snackbar"
                color="white"
                rounded="pill"
                :timeout="0"
              >
                <div class="snackbar-wrapper">
                  <div style="display:flex; align-items:start">
                    <v-img contain height="40px" width="40px" src="/assets/images/tip.png"></v-img>
                  </div>
                  <p class="remove-margin">{{ $t(selectedTip)}}</p>
                </div>
              </v-snackbar>
          </div>
        </template>
        <template v-if="actualStep > 2">
          <div class="import-resume--inner-body">
            <v-icon size="36" color="#2C2C2C">mdi-alert-circle-outline</v-icon>
            <h4>{{ $t(statusError.title) }}</h4>
            <div class="import-resume--error-banner">
              <v-icon color="#F44336" size="18" class="mr-2">mdi-alert</v-icon>
              <p>{{ $t(statusError.subtitle) }}</p>
            </div>
          </div>
        </template>
        <template v-if="actualStep === 2">
          <div class="import-resume--inner-body">
            <div class="import-resume--revision-banner">
              <v-icon color="#FFBA00" size="18" class="mr-2">mdi-alert</v-icon>
              <p>{{ $t('import.resume.modal:revision.banner.message') }}</p>
            </div>
            <profile-info-form v-model="formData" :validations="$v" ></profile-info-form>
          </div>
        </template>
      </div>
      <div class="import-resume--bottom-actions">
        <v-btn class="btn transform-unset mr-2" :color="getPrimaryColor" outlined @click="$emit('close')">{{ $t('global:cancel') }}</v-btn>
        <input @change="storeResume" id="resumeUpload" type="file" accept="application/pdf" hidden>
        <v-btn class="btn transform-unset white--text" :color="getPrimaryColor" :disabled="isResumeLoading || isAnalyzing"  @click="selectResume"><v-icon class="mr-2" size="20" color="#fff">{{ actualStep === 2 ? 'mdi-check' : 'mdi-file-upload-outline'}}</v-icon>{{ $t(buttonText) }}</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">

.import-resume--container {
  overflow-y: hidden;
  .snackbar-wrapper {
    .remove-margin {
      margin-bottom: 0px;
    }
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    p {
      margin-left: 10px;
    }
  }
  .import-resume--head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    background: #FAFAFA;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    h4 {
      font-family: $lato;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #454545;
    }
  }
  .import-resume--body {
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    .progress-bar--wrapper {
      padding: 16px 24px 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }
    .import-resume--inner-body {
      padding: 16px 24px;
      > h4 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #242321;
        margin-bottom: 16px;
      }
      > p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #7E7D77;
        margin-bottom: 8px;
        span.icon-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 14px;
          height: 14px;
          background: rgba(126, 125, 119, 0.1);
          border-radius: 900px;
        }
      }
      .import-resume--error-banner {
        background: rgba(244, 67, 54, 0.1);
        border-radius: 4px;
        padding: 8px 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p {
          margin-bottom: 0;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #242321;
        }
      }
      .import-resume--revision-banner {
        background: #FFF1CC;
        border-radius: 4px;
        padding: 8px 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p {
          margin-bottom: 0;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #242321;
        }
      }
      .check-input {
        fieldset {
          border-color: #FFBA00;
        }
      }
    }
  }
  .import-resume--bottom-actions {
    padding: 16px 24px;
    background: #FAFAFA;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    text-align: right;
  }
}
</style>
