<script>

import ProfilePictureManage from './components/ProfilePictureManage'
import ProfileInfoForm from './components/ProfileInfoForm.vue'
import { getCountries } from '@/support/countryinfo/country-codes.js'
import CompletitionCriteriaList from '../general/CompletitionCriteriaList'
import ImportResumeModal from './components/ImportResumeModal'
import { required, email, requiredIf, maxValue } from 'vuelidate/lib/validators'

export default {
  name: 'NewProfileForm',
  components: {
    ProfilePictureManage,
    ProfileInfoForm,
    CompletitionCriteriaList,
    ImportResumeModal
  },
  computed: {
    getUser () {
      return this.$store.getters.getUser
    },
    completitionCriteria () {
      const criterias = {
        'contact.data': false,
        'personal.data': false,
        'social.media.data': false,
        'professional.data': false,
        'professional.experience.data': false,
        'academic.experience.data': false,
        'languages.data': false
      }
      if (
        this.formData.name &&
        this.formData.lastName &&
        this.formData.city &&
        ((this.formData.isPCD && this.formData.pcdType && this.formData.pcdCID) || !this.formData.isPCD) &&
        ((this.formData.needAdaptation && this.formData.pcdAdaptation) || !this.formData.needAdaptation)
      ) {
        criterias['personal.data'] = true
      }

      if (
        this.formData.email &&
        this.formData.phone
      ) {
        criterias['contact.data'] = true
      }

      if (
        this.formData.socialMedia.length > 0
      ) {
        criterias['social.media.data'] = true
      }

      if (
        this.formData.professionalTitle &&
        this.formData.professionalDescription &&
        this.formData.preferredAreas.length > 0
      ) {
        criterias['professional.data'] = true
      }

      if (
        this.formData.hasProfessionalExp ||
        this.formData.professionalExperiences.length > 0
      ) {
        criterias['professional.experience.data'] = true
      }

      if (
        this.formData.hasAcademicExp ||
        this.formData.academicExperiences.length > 0
      ) {
        criterias['academic.experience.data'] = true
      }

      if (
        this.formData.languages.length > 0
      ) {
        criterias['languages.data'] = true
      }

      const returnableValue = []
      Object.keys(criterias).forEach(key => {
        returnableValue.push({ key, completed: criterias[key] })
      })
      return returnableValue
    }
  },
  data () {
    return {
      universitySearch: '',
      showForm: false,
      universityOptions: [],
      courseSearch: '',
      courseOptions: [],
      companySearch: '',
      companyOptions: [],
      jobTitleSearch: '',
      jobTitleOptions: [],
      profileInfo: {},
      positionsCategory: [],
      languageList: {},
      showImportResumeModal: false,
      tempArea: null,
      initialSocialMedia: null,
      socialMediaOptions: {
        Linkedin: 'https://www.linkedin.com/',
        Instagram: 'https://www.instagram.com/',
        Twitter: 'https://www.twitter.com/',
        Facebook: 'https://www.facebook.com/',
        GitHub: 'https://www.github.com/',
        Tiktok: 'https://www.tiktok.com/',
        Dribbble: 'https://www.dribbble.com/',
        Behance: 'https://www.behance.com/',
        Blog: 'https://',
        Site: 'https://'
      },
      initialFormData: {},
      formData: {
        emailResponse: null,
        countryCode: '',
        cityOptions: [],
        cityIdOptions: {},
        name: '',
        lastName: '',
        city: '',
        birthdate: '',
        region: '',
        skills: [],
        countryAbbr: '',
        email: '',
        phone: '',
        isPCD: false,
        pcdType: '',
        pcdCID: '',
        needAdaptation: false,
        pcdAdaptation: '',
        socialMedia: [],
        professionalTitle: '',
        professionalDescription: '',
        preferredAreas: [],
        hasProfessionalExp: true,
        professionalExperiences: [],
        hasAcademicExp: true,
        academicExperiences: [],
        languages: []
      }
    }
  },
  validations () {
    const validateBirthDate = (value) => {
      if (value === '') return true
      if (value.split('/').length !== 3) return false
      const [day, month, year] = value.split('/')

      if (!(day.length === 2) || !(month.length === 2) || !(year.length === 4)) return false

      const birthDate = new Date()
      birthDate.setMonth(month - 1)
      birthDate.setDate(day)
      birthDate.setFullYear(year)

      if (birthDate.getTime() > new Date().getTime()) return false
      const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
      if (!value.match(regex)) return false
      return true
    }
    const initEndDateYear = (value) => {
      if (value.actualWork || value.isStudying) return true
      if (!value.dateInit || !value.dateEnd) return true
      const yearInit = parseInt(value.dateInit)
      const yearEnd = parseInt(value.dateEnd)
      if (yearInit > yearEnd) return false
      return true
    }
    const initEndDate = (value) => {
      if (value.actualWork || value.isStudying) return true
      if (!value.dateInit || !value.dateEnd) return true
      const monthInit = parseInt(value.dateInit.split('/')[0])
      const monthEnd = parseInt(value.dateEnd.split('/')[0])
      const yearInit = parseInt(value.dateInit.split('/')[1])
      const yearEnd = parseInt(value.dateEnd.split('/')[1])
      if (yearInit > yearEnd) return false
      if (yearInit === yearEnd && monthInit > monthEnd) return false
      return true
    }
    const notUsedEmail = (value) => {
      if (!this.formData.emailResponse) return true
      if (this.formData.emailResponse.status === 200 && value !== this.getUser.email) return false
      return true
    }
    const isEnliztEmail = (val) => {
      if (!this.formData.emailResponse) return true
      if (this.formData.emailResponse.status === 400 || this.formData.emailResponse.status === 403 || ['enlizt.com', 'enlizt.com.br', 'enlizt.br'].includes(val.split('@')[1])) return false
      return true
    }
    const validFormatMonthYear = (value) => {
      if (!value) return true
      if (!value.includes('/')) return false
      const month = value.split('/')[0]
      const year = value.split('/')[1]
      if (month.length !== 2 || year.length !== 4) return false
      if (parseInt(month) > 12) return false
      return true
    }
    const limitDateMonthYear = (value) => {
      if (!value) return true
      if (!value.includes('/')) return false
      const actualYear = new Date().getUTCFullYear()
      const actualMonth = ('0' + (new Date().getUTCMonth() + 1)).slice(-2)
      const month = parseInt(value.split('/')[0])
      const year = parseInt(value.split('/')[1])
      if (year > actualYear) return false
      if (year === actualYear && month > actualMonth) return false
      return true
    }
    const validatePhoneDDD = (input) => {
      if (this.formData.countryCode !== 'br') return true
      if (!input) return false
      const value = input.replace('(', '').replace(')', '').replace(' ', '')
      if (value.length === 11) {
        var validDDD = [11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 63, 64, 65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99]
        const ddd = value.slice(0, 2)
        if (validDDD.indexOf(parseInt(ddd)) !== -1) return true
        return false
      } else return false
    }
    const validatePhoneNumber = (input) => {
      if (this.formData.countryCode !== 'br') return true
      if (!input) return false
      const value = input.replace('(', '').replace(')', '').replace(' ', '')
      if (value.length === 11) {
        var validFirstDigit = ['6', '7', '8', '9']
        const tel = value.slice(2)
        if (validFirstDigit.indexOf(tel[0]) !== -1) {
          return true
        }
        return false
      } else return false
    }
    const validateCountryAbbr = () => {
      if (this.formData.region !== 'BRA' || this.formData.region !== 'USA') {
        return true
      } else {
        return false
      }
    }
    const hasAcademicExperiencesValidation = (value) => {
      if (this.formData.academicExperiences.length > 0) {
        return true
      }
      return value === true
    }
    const hasProfessionalExperiencesValidation = (value) => {
      if (this.formData.professionalExperiences.length > 0) {
        return true
      }
      return value === true
    }
    const validateSocialMedias = (value) => {
      if (!value.type || !value.value) return false
      if (value.type === '' || value.value === '') return false
      const secureLink = this.socialMediaOptions[value.type]
      if (value.value && ((value.value.indexOf(secureLink) !== 0 && value.value.indexOf(secureLink.replace('www.', '')) !== 0) || value.value.includes(' ') || value.value.includes('?'))) return false
      else return true
    }
    return {
      formData: {
        name: { required },
        lastName: { required },
        birthdate: { validateBirthDate },
        countryAbbr: {
          validateCountryAbbr
        },
        // region: { required },
        city: { required },
        email: { required, email, notUsedEmail, isEnliztEmail },
        phone: { required, validatePhoneDDD, validatePhoneNumber },
        isPCD: '',
        pcdType: {
          required: requiredIf(function (formData) {
            return formData.isPCD
          })
        },
        pcdCID: {
          required: requiredIf(function (formData) {
            return formData.isPCD
          })
        },
        needAdaptation: '',
        pcdAdaptation: {
          required: requiredIf(function (formData) {
            return formData.needAdaptation
          })
        },
        socialMedia: {
          $each: { required, validateSocialMedias }
        },
        professionalTitle: { required },
        professionalDescription: { required },
        preferredAreas: { required },
        hasProfessionalExp: {
          hasProfessionalExperiencesValidation
        },
        professionalExperiences: {
          $each: {
            initEndDate,
            company: { required },
            title: { required },
            dateInit: { required, validFormatMonthYear, limitDateMonthYear },
            actualWork: '',
            dateEnd: {
              required: requiredIf(function (experience) {
                return !experience.actualWork
              }),
              validFormatMonthYear,
              limitDateMonthYear
            }
          }
        },
        hasAcademicExp: {
          hasAcademicExperiencesValidation
        },
        academicExperiences: {
          $each: {
            initEndDateYear,
            institution: { required },
            title: { required },
            dateInit: {
              required,
              limitDateYear: maxValue(new Date().getUTCFullYear())
            },
            isStudying: '',
            dateEnd: {
              required: requiredIf(function (experience) {
                return !experience.isStudying
              }),
              limitDateYear: maxValue(new Date().getUTCFullYear())
            }
          }
        },
        languages: {
          $each: {
            name: { required },
            proficiency: { required }
          }
        }
      }
    }
  },
  methods: {
    countryToCode (country) {
      const countriesInfo = getCountries()
      const referenceObject = {}
      Object.keys(countriesInfo).forEach(el => { referenceObject[el] = countriesInfo[el].dialCode })
      return referenceObject[country]
    },
    updateGlobalInfo () {
      const userObject = {
        email: this.formData.email,
        name: this.formData.name,
        firstName: this.formData.name,
        lastName: this.formData.lastName,
        region: this.formData.region
      }
      this.$store.dispatch('attemptUpdateUserGlobalInfo', { ...userObject })
      this.$store.dispatch('attemptUpdateIndividualWorkspace', { ...userObject })
    },
    getUpdatedSocialMedia () {
      const socialMedias = {}
      this.formData.socialMedia.forEach(s => {
        if (!this.initialSocialMedia.some(initial => initial.type === s.type)) this.initialSocialMedia.push(s)
      })
      const removeSocialMedia = this.initialSocialMedia.filter(oldSocial => !this.formData.socialMedia.some(newSocial => newSocial.type === oldSocial.type))
      if (removeSocialMedia.length) {
        this.initialSocialMedia.forEach(socialmedia => {
          socialMedias[socialmedia.type.toLowerCase()] = removeSocialMedia.some(removeSocial => removeSocial.type === socialmedia.type) ? '' : socialmedia.value
        })
        return socialMedias
      }
      this.formData.socialMedia.forEach(socialmedia => {
        socialMedias[socialmedia.type.toLowerCase()] = socialmedia.value
      })
      return socialMedias
    },
    getUpdatedPersonal () {
      const personalInfo = {}
      personalInfo.countryAbbr = this.formData.countryAbbr
      personalInfo.dateOfBirth = this.formData.birthdate !== '' ? this.formatDateToTimeStamp(this.formData.birthdate) : ''
      personalInfo.disability = this.formData.pcdType
      personalInfo.isDisabledPerson = !!this.formData.isPCD
      personalInfo.region = this.formData.region
      personalInfo.email = this.formData.email
      personalInfo.firstName = this.formData.name
      personalInfo.lastName = this.formData.lastName
      personalInfo.location = this.formData.city
      personalInfo.locationId = this.formData.cityId
      personalInfo.medicalReportICD = this.formData.pcdCID
      personalInfo.phone = `+${this.countryToCode(this.formData.countryCode)} ${this.formData.phone}`
      personalInfo.specialAdaptationNeeded = this.formData.pcdAdaptation
      personalInfo.title = this.formData.professionalTitle
      personalInfo.description = this.formData.professionalDescription
      return personalInfo
    },
    getUpdatedEducation () {
      if (this.formData.academicExperiences.length === 0) return []
      return this.formData.academicExperiences.map((academicExp) => {
        return { course: academicExp.title, id: academicExp.id, isStudying: academicExp.isStudying, periodFrom: this.formatYearToTimestamp(academicExp.dateInit), periodTo: this.formatYearToTimestamp(academicExp.dateEnd), school: academicExp.institution }
      })
    },
    formatDateToTimeStamp (myDate) {
      myDate = myDate.split('/')
      var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]).toISOString()
      return newDate
    },
    checkAcademicFormationsToDelete () {
      const stringfiedFomations = this.formData.academicExperiences.map(el => JSON.stringify(el))
      return this.initialFormData.academicExperiences.map((academicExp) => {
        if (!stringfiedFomations.includes(JSON.stringify(academicExp))) return academicExp.id
      }).filter(el => el !== undefined)
    },
    deletionHandler () {
      const removeObject = { filter: '' }
      const deleteEducation = this.checkAcademicFormationsToDelete()
      if (deleteEducation.length > 0) {
        removeObject.filter += 'education,'
        removeObject.education = deleteEducation
      }
      removeObject.filter = removeObject.filter.slice(0, -1)
      return removeObject
    },
    updateHandler () {
      const updateObject = { filter: 'social-links, work-experience, personal-details, education, categories, languages, skills' }
      updateObject.workExperience = this.formData.professionalExperiences.map((workexp) => {
        return { title: workexp.title, companyName: workexp.company, periodFrom: this.formatMonthYearToTimestamp(workexp.dateInit), periodTo: this.formatMonthYearToTimestamp(workexp.dateEnd), isCurrentWork: workexp.actualWork, id: workexp.id, description: workexp.description }
      })
      updateObject.socialLinks = this.getUpdatedSocialMedia()
      updateObject.personalDetails = this.getUpdatedPersonal()
      updateObject.education = this.getUpdatedEducation()
      updateObject.categories = this.formData.preferredAreas
      updateObject.languages = this.getUpdatedLanguages()
      updateObject.skills = this.formData.skills
      return updateObject
    },
    getUpdatedLanguages () {
      const myObj = {}
      this.formData.languages.forEach(language => {
        myObj[language.name] = language.proficiency
      })
      return myObj
    },
    sendForm () {
      this.$v.formData.$touch()
      if (!this.$v.$error) {
        this.updateGlobalInfo()
        const updateObject = this.updateHandler()
        const deleteObject = this.deletionHandler()
        if (deleteObject.filter !== '') {
          this.$store.dispatch('attemptDeleteUserProfile', deleteObject)
        }
        this.initialSocialMedia = [...this.formData.socialMedia]
        this.$store.dispatch('attemptUpdateUserProfile', updateObject).then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('modal.change.profile.member:feedback.success')
          })
        })
      } else {
        if (!this.formData.isPCD) {
          this.$v.formData.pcdType.$reset()
          this.$v.formData.pcdCID.$reset()
        }
        if (!this.formData.needAdaptation) {
          this.$v.formData.pcdAdaptation.$reset()
        }
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'error',
          title: this.$t('global:validation.form.required')
        })
      }
    },
    formatYearToTimestamp (date) {
      if (date === '' || !date) return date
      return `${date}-01-01T00:00:00.000Z`
    },
    formatMonthYearToTimestamp (date) {
      if (date === '' || !date) return date
      const month = date.split('/')[0]
      const year = date.split('/')[1]
      return `${year}-${month}-01T00:00:00.000Z`
    },
    fillUserInfos () {
      const userInfos = this.getUser
      this.formData.name = userInfos.firstName
      this.formData.lastName = userInfos.lastName
      this.formData.email = userInfos.email
    },
    overwriteFormData (input) {
      const newData = { ...input }
      if (newData.skills?.length === 0) newData.skills = [...this.formData.skills]
      this.formData = newData
    }
  },
  created () {
    this.fillUserInfos()
    this.$store.dispatch('attemptGetUserProfile').then(data1 => {
      this.initialSocialMedia = data1.socialLinks ? Object.keys(data1.socialLinks)?.map((link) => {
        if (data1.socialLinks[link] === '') return
        return { type: this.capitalizeFirstLetter(link), value: data1.socialLinks[link] }
      }).filter(link => link !== undefined) : []
      const newFormData = { ...this.formData, ...this.convertFormData(data1) }
      if (newFormData.city === '' || newFormData.professionalTitle === '') {
        newFormData.hasProfessionalExp = false
        newFormData.hasAcademicExp = false
      }
      this.formData = { ...newFormData }
      this.initialFormData = { ...newFormData }
    }).finally(() => {
      this.$nextTick(() => {
        this.showForm = true
      })
    })
  }
}
</script>
<template>
  <div class="profile-form--container">
    <div class="profile-form--left">
      <profile-picture-manage v-model="getUser.avatar"/>
      <profile-info-form v-if="showForm" v-model="formData" :validations="$v" ></profile-info-form>
      <div class="profile-form--actions">
        <v-btn class="btn transform-unset" :color="getPrimaryColor" @click="sendForm"  dark><v-icon class="mr-2" size="20" color="#fff">mdi-content-save-outline</v-icon>{{ $t('global:save.changes') }}</v-btn>
      </div>
    </div>
    <div class="profile-form--right">
      <v-btn class="btn transform-unset" :color="getPrimaryColor" dark x-large @click="showImportResumeModal = true"><v-icon size="20" color="#fff" class="mr-2">mdi-file-upload-outline</v-icon>{{ $t('settings.profile.labels:import.resume') }}</v-btn>
      <completition-criteria-list
        fixed
        title="profile.fill:complete.your.profile"
        description="profile.fil:complete.your.profile.description"
        :actualStep="5"
        :steps="10"
        :criterias="completitionCriteria"
      ></completition-criteria-list>
    </div>
    <import-resume-modal @overwrite-form-data="this.overwriteFormData" v-if="showImportResumeModal" @close="showImportResumeModal = false"></import-resume-modal>
  </div>
</template>
<style lang="scss">
.profile-form--container {
  display: flex;
  width: 100%;
  .profile-form--left {
    width: calc(100% - 240px);
  }
  .profile-form--right {
    width: 200px;
    margin-left: 40px;
  }
  .profile-form--actions {
    text-align: right;
    margin: 40px 0 80px;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column-reverse;
    .profile-form--left {
      width: 100%;
    }
    .profile-form--right {
      width: 100%;
      margin: 0 0 40px 0;
    }
    .completition-criteria-list--container {
      display: none;
    }
  }
}
</style>
