<script>
export default {
  name: 'ProgressBar',
  props: {
    value: {
      type: Number
    },
    status: {
      type: String,
      default: 'loading'
    },
    rightText: {
      type: String,
      default: ''
    },
    indeterminate: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<template>
  <div class="progress-bar--container">
    <div class="progress-bar--texts">
      <p class="status" :style="{color: status !== 'error' ? '#36D75E' : '#F44336'}">{{ $t(`progress.bar.status:${status}`) }}</p>
      <p class="right-text">{{ rightText }}</p>
    </div>
    <v-progress-linear
      :value="indeterminate? 0 : value"
      :color="status !== 'error' ? '#36D75E' : '#F44336'"
      height="8"
      rounded
      :indeterminate="this.indeterminate"
    ></v-progress-linear>
  </div>
</template>
<style lang="scss">
.progress-bar--container {
  .progress-bar--texts {
    display: flex;
    justify-content: space-between;
    p.status {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 4px;
    }
    p.right-text {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #7E7D77;
      margin-bottom: 4px;
    }
  }
}
</style>
